<template>
  <div class="team-member-block">
    <img class="team-member" :src="returnImage(memberInfo.image)" />
    <div class="team-member-name">
      {{ memberInfo.name }}
      <span class="team-member-role">{{ memberInfo.role }}</span>
    </div>
  </div>
</template>

<script>
import API from "@/api/utils";

export default {
  props: {
    memberInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    returnImage(img) {
      if (img) {
        const imgLoc = img ? img.url : "";
        const host = img ? API.getBaseURL() : "";
        return `${host}${imgLoc}`;
      } else {
        return require("@/assets/images/face.svg");
      }
    }
  }
};
</script>

<style></style>
