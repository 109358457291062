<template>
  <main class="portfolio teams" v-if="isMemberEmpty">
    <section class="hero">
      <div class="hero-border-left"></div>

      <div class="hero-block fade-in-bck">
        <div class="hero-title fade-in-bck">
          OUR TEAM
          <span class="hero-title-bar fade-in-bck"></span>
        </div>

        <div class="hero-subtitle">
          We're driven by the idea that amazing work is born every time you
          combine creativity, fun and hard work. Meet the team that makes this
          magic happen.
          <a
            data-mixpanel="team_cta_get_free_consultation"
            href="mailto:info@countable.ca"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
          >LET'S TALK</a>
        </div>
      </div>

      <!--TEAM-->
      <div id="team" class="portfolio-section rev">
        <div class="team-wrapper">
          <TeamMember v-for="member in memberList" :key="member.id" :member-info="member" />
        </div>
      </div>
    </section>
  </main>
  <!-- Show loading spinner if content is still loading -->
  <div v-else class="loading-section">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import TeamMember from "@/components/TeamMember";
export default {
  components: {
    TeamMember
  },
  computed: {
    memberList() {
      return this.$store.getters.getTeamList;
    },
    isMemberEmpty() {
      return this.memberList.length !== 0;
    }
  },
  created() {
    this.$store.dispatch("fetchTeamMemberAPI");
  }
};
</script>

<style>
</style>
